<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { i18n } from "vue-lang-router";

export default {
    name: 'photoEng',

    data() {
        return {
            name: '',
            email: '',
        };
    },

    created() {
        document.title = "Tempus Picture Agency – a picture archive for history!";
    },

    computed: {
        ...mapGetters(['isLoading']),

    },

    methods: {
        send() {
            if (this.name == '' || this.email == '') {
                alert('Please complete all fields');
                return;
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this.email)) {
                alert('Email field must have email form');
                return;
            }
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/newsletter/save`,
                { name: this.name, email: this.email }).then(() => {
                    this.name = "";
                    this.email = "";
                    alert('Saved');
                });
        },
    },

    components: {},
};
</script>

<template>
    <div class="illustration-info-container">
        <div class="illustration-info">
            <div class="content">
                <div class="header p-4">
                    <h1 class="text-3xl">Tempus Picture Agency – a picture archive for history!</h1>
                </div>
                <br>
                <div class="content-paragraph">
                   Tempus Picture Agency delivers digital image files to book publishers, magazines, museums, advertising agencies and anyone else who needs documentary pictures within historical topics. Our specialty is black and white illustrations from books and magazines mainly from the period 1830–1900. We scan the pictures and remove stains and paper colour digitally, after which they are registered in our database.
                   <br/><br/>
                   The archive includes around one million pictures in antique books. The pictures available in the database here on the website are thus only a small fraction, but the digitization of new pictures progresses relentlessly. If you do not find a motif that you are looking for, please <a href="kontakt">contact us</a>. We are happy to help with research and acquisition of pictures – from one-off requests to full-fledged assignments as a picture editor.
                </div>

                <div class="content-paragraph borderedImage">
                    <img src="@/assets/lillustration.jpg" alt="Tempus Picture Agency’s archive contains pictures from antique books and magazines. Here is a spread in the French magazine L’illustration from 1892.">
                    <p>Tempus Picture Agency’s archive contains pictures from antique books and magazines. Here is a spread in the French magazine <i>L’illustration</i> from 1892.</p>
                </div>

                 <div class="content-paragraph">
                    Tempus Picture Agency therefore specializes in history. This means not only that the pictures are old, but also that they are placed in a historical context. What, then, is history? It is, of course, everything that has happened before the present, but history is also the narration and ordering of the past. What makes our picture agency unique is that the presentation of the pictures takes historical context into account. We have taken particular note of classifying phenomena and placing them in a context. This makes it easier for you when you are looking for pictures within a particular theme.
                    <br/><br/>
                    Tempus Picture Agency offers only pictures whose authors have been deceased for more than seventy years. This means that the financial copyright has ended and that the author’s descendants are no longer entitled to compensation when someone uses the picture.
                </div>
                <div class="content-paragraph">
                    <p>The rest of the website is only available in Swedish, but we are happy to answer questions in English. Wir beantworten gerne auch Fragen auf Deutsch.</p>
                </div>
                <div class="content-paragraph borderedImage">
                    <img src="@/assets/vom-kriegsschauplatz.jpg" alt="The illustrated newspaper Vom Kriegsschauplatz< was published one to three times a week during the Franco-German War of 1870–1871 with a total of sixty issues, each with eight pages.">
                    <p>The illustrated newspaper <i>Vom Kriegsschauplatz</i> was published one to three times a week during the Franco-German War of 1870–1871 with a total of sixty issues, each with eight pages.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.illustration-info-container {
    text-align: left;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.05);

    .illustration-info {
        margin: 0 25%;
        // height: 100vh;
        background-color: #fff;

        @media only screen and (max-width: 992px) {
            margin: 0 20px;
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: 600px) {
            width: auto;
        }

        // @media screen and (max-width: $bp-mobile) {
        //   width: 100%;
        //   margin: 0;
        // }

        .header {
            span {
                margin: 0 1em;
                cursor: pointer;
            }

            h3 {
                padding: 1em;
            }
        }

        .content {
            padding: 1em;

            h1 {
                font-size: 30px;
                font-weight: 500;
                line-height: 36px;
            }

              .borderedImage{
                border: 1px solid gray;
                text-align: center;
                font-size: 14px;
                height: fit-content;

                    @media only screen and (max-width: 600px) {
                        width: 100%;
                    }
                }

            .content-paragraph {
                font-size: 16px;
                font-weight: 500;
                line-height: 29px;

                ul{
                    list-style-type: disc;
                    padding-left: 20px;
                }
                
                a{
                    text-decoration: underline;
                    color: #1c1c68;
                }

              
            }

            div {
                padding: 1em;
            }

            .about {
                p {
                    margin: 0;
                    font-size: 0.9em;
                    line-height: 1.4em;
                }
            }

            .category {
                div {
                    padding: 0;
                    margin: 1em 0;

                    &:last-of-type {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

            .description {
                background-color: #dfe6e9;
                padding-left: 0;
                padding-right: 0;
            }

            .tags {
                display: block;

                .badge {
                    display: inline-block;
                    font-size: 0.75em;
                    padding: 0.5em 0.75em;
                    border-radius: 5px;
                    color: #fff;
                    margin: 0 0.5em 0.5em 0;
                    background-color: #1c1c68;
                }
            }
        }
    }


}
</style>
